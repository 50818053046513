<template>
  <q-item class="q-px-none q-pt-md">
    <q-item-section>
      <q-item-label caption>
        <q-skeleton type="text" style="max-width: 200px" />
      </q-item-label>
      <q-item-label>
        <q-skeleton type="text" style="max-width: 150px" />
      </q-item-label>
      <q-item-label caption>
        <q-skeleton type="text" style="max-width: 200px" />
      </q-item-label>
    </q-item-section>
  </q-item>
</template>
